import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Button,
  Image,
  Section,
  Strong,
  Icon,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Ласкаво просимо на автомийку XovileXusa!</title>
        <meta name={"description"} content={"Їдьте чистими, їдьте щасливими"} />
        <meta
          property={"og:title"}
          content={"Головна | Ласкаво просимо на автомийку XovileXusa!"}
        />
        <meta
          property={"og:description"}
          content={"Їдьте чистими, їдьте щасливими"}
        />
        <meta
          property={"og:image"}
          content={"https://win.xovilexusa.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://win.xovilexusa.com/img/car-wash.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://win.xovilexusa.com/img/car-wash.png"}
        />
      </Helmet>
      <Components.Header />

      <Section
        padding="160px 0 170px 0"
        background="radial-gradient(circle at center,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://top.kezobolu.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
        md-padding="80px 0 90px 0"
        quarkly-title="Hero-9"
      >
        <Box
          min-width="100px"
          min-height="100px"
          lg-width="100%"
          display="flex"
          flex-direction="column"
          align-items="center"
        >
          <Text
            margin="0px 0px 20px 0px"
            font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
            sm-font="normal 700 42px/1.2 --fontFamily-sans"
            padding="0px 200px 0px 200px"
            text-align="center"
            color="--light"
            lg-padding="0px 0 0px 0"
          >
            XovileXusa автомийка!
          </Text>
          <Text
            margin="0px 0px 50px 0px"
            font="--lead"
            color="--light"
            padding="0px 50px 0px 50px"
            text-align="center"
            lg-padding="0px 0 0px 0"
          >
           Пориньте у світ автомийки XovileXusa, де ми поєднуємо технології, зручність та пристрасть до деталей, щоб забезпечити унікальний досвід миття автомобілів. Тут кожна мийка - це не просто чистота, а прагнення зробити так, щоб ваш автомобіль виглядав якнайкраще. Приєднуйтесь до нас, щоб отримати новий підхід до догляду за автомобілем.
          </Text>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            sm-flex-direction="column"
            sm-align-self="stretch"
          >
            <Button
              margin="0px 15px 0px 0px"
              padding="12px 50px 12px 50px"
              background="#dc4a3c"
              border-radius="8px"
              font="normal 400 17px/1.5 --fontFamily-sans"
              sm-margin="0px 0 15px 0px"
              transition="background-color 0.2s ease 0s"
              border-width="2px"
              border-style="solid"
              border-color="#dc4a3c"
              hover-background="rgba(63, 36, 216, 0)"
              href="/contacts"
              type="link"
              text-decoration-line="initial"
            >
              Запланувати візит
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://win.xovilexusa.com/img/2.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Про Xovile Xusa
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
           На автомийці XovileXusa ми розуміємо, що ваш автомобіль - це більше, ніж просто засіб пересування. Саме тому ми використовуємо новітні технології миття та екологічно чисті рішення, щоб гарантувати, що кожен автомобіль залишає наші приміщення не тільки чистим, але й захищеним. Наші зони самообслуговування обладнані зручними системами, що дозволяє вам насолоджуватися миттям без зайвих клопотів.
          </Text>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="5%"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          width="60%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="48px 32px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          md-grid-gap="36px "
        >
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Виняткова чистота</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Наше сучасне миюче обладнання та високоякісні засоби працюють разом, щоб забезпечити виняткові результати. Від ніжної піни до потужних струменів - ваш автомобіль буде доглянутий зверху донизу.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Максимальна зручність</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Відкриті 24/7, наші послуги створені для вашої зручності. Рано вранці чи пізно ввечері, наші послуги доступні, щоб відповідати вашому напруженому графіку.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Конкурентоспроможні ціни </Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Насолоджуйтесь чудовими послугами автомийки за вигідною ціною. XovileXusa пропонує різні варіанти цін, щоб задовольнити ваші конкретні потреби без шкоди для якості.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Екологічно чисті практики</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Ми прагнемо до сталого розвитку. Наша автомийка використовує перероблену воду та біологічно розкладні хімічні речовини для зменшення впливу на навколишнє середовище, що робить нас відповідальним вибором для екологічно свідомих водіїв.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="40%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://win.xovilexusa.com/img/3.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
